<template>
  <h2>Page Not Found</h2>
  <p></p>
</template>

<script>
export default {
  name: 'not-found',
  created: function () {},
  methods: {},
}
</script>

<style scoped></style>
