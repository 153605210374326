import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

// css reset
import '@/assets/css/reset.css'
// css global styles
import '@/assets/css/styles.css'
// css global transitions
import '@/assets/css/transitions.css'
// css applicant form
import '@/assets/css/applicantForm.css'

import { VueReCaptcha } from "vue-recaptcha-v3";

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
library.add(faBars, faMagnifyingGlass)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const app = createApp(App)
const vuetify = createVuetify()

// Vue Click Away
import VueClickAway from "vue3-click-away"

app.use(router)
app.use(store)
app.use(vuetify)
app.use(VueClickAway) // Makes 'v-click-away' directive usable in every component
// app.use(createMetaManager())
app.use(VueReCaptcha, { siteKey: "6Le-KL0ZAAAAANg0XVvLWppfmpGimVdYp5YlYkPW" })
app.component('font-awesome-icon', FontAwesomeIcon)

// Google Analytics
app.use(VueGtag, {
  // Enable screenviews
  appName: 'DeepSea Careers Application',
  pageTrackerScreenviewEnabled: true,
  // Use custom template instead of default
  // pageTrackerTemplate(to) {
  //   return {
  //     page_title: '',
  //     page_path: to.path
  //   }
  // },
  // Exclude SeeScan route paths/names from auto tracking
  pageTrackerExcludedRoutes: [
    // SeeScan Positions
    '/',
    'positions',
    // SeeScan Position Description
    '/position/:id',
    'position-description',
    // SeeScan Applicant Form
    // '/application/:id',
    'applicant-form',
    // SeeScan Applicant Response
    // '/response/:id/:status',
    'applicant-response'
  ],
  config: { id: 'G-HTPFWNF6J3' }, // DeepSea
}, router)
app.use(VueGtag, {
  // Enable screenviews
  appName: 'SeeScan Careers Application',
  pageTrackerScreenviewEnabled: true,
  // Exclude DeepSea route paths/names from auto tracking
  pageTrackerExcludedRoutes: [
    // DeepSea Positions
    '/deepsea',
    'deepsea-positions',
    // DeepSea Position Description
    'deepsea/position/:id',
    'deepsea-position-description',
    // DeepSea Applicant Form
    // '/application/:id',
    'deepsea-applicant-form',
    // DeepSea Applicant Response
    // '/response/:id/:status',
    'deepsea-applicant-response'
  ],
  config: { id: 'G-F5V4E3ZR3L' }, // SeeScan
}, router)
// app.provide('gtag', app.config.globalProperties.$gtag);


// Google Analytics
app.use(VueGtag, {
  // Enable screenviews
  appName: 'DeepSea Careers Application',
  pageTrackerScreenviewEnabled: true,
  // Use custom template instead of default
  // pageTrackerTemplate(to) {
  //   return {
  //     page_title: '',
  //     page_path: to.path
  //   }
  // },
  // Exclude SeeScan route paths/names from auto tracking
  pageTrackerExcludedRoutes: [
	// SeeScan Positions
	'/',
	'positions',
	// SeeScan Position Description
	'/position/:id',
	'position-description',
	// SeeScan Applicant Form
	// '/application/:id',
	'applicant-form',
	// SeeScan Applicant Response
	// '/response/:id/:status',
	'applicant-response'
  ],
  config: { id: 'G-HTPFWNF6J3' }, // DeepSea
}, router)
app.use(VueGtag, {
  // Enable screenviews
  appName: 'SeeScan Careers Application',
  pageTrackerScreenviewEnabled: true,
  // Exclude DeepSea route paths/names from auto tracking
  pageTrackerExcludedRoutes: [
	// DeepSea Positions
	'/deepsea',
	'deepsea-positions',
	// DeepSea Position Description
	'deepsea/position/:id',
	'deepsea-position-description',
	// DeepSea Applicant Form
	// '/application/:id',
	'deepsea-applicant-form',
	// DeepSea Applicant Response
	// '/response/:id/:status',
	'deepsea-applicant-response'
  ],
  config: { id: 'G-F5V4E3ZR3L' }, // SeeScan
}, router)
// app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app')
