<template>
  <div class="deepsea-response-msg">
    <h2>{{ result.title }}</h2>
    <Separator :color="'#ffda00'" />
    <p>{{ result.desc }}</p>
    <!-- Success Btn -->
    <a href="https://www.deepsea.com" v-if="this.status == 1">
      <Button class="deepsea-response-btn" :deepsea="true">Explore DeepSea</Button>
    </a>
    <!-- Redirect Btn -->
    <router-link to="/deepsea" v-else>
      <Button class="deepsea-response-btn">DeepSea Jobs</Button>
    </router-link>
  </div>
</template>

<script>
import Separator from '@/components/Separator.vue'
import Button from '@/components/buttons/Button.vue'

export default {
  name: 'deepsea-response',
  components: {
    Separator,
    Button
  },
  data() {
    return {
      response_loading: false,
      pos_error: '',
      status: null,
      result: {
        title: null,
        desc: null
      }
    }
  },
  created: function() {

    this.status = this.$route.params.status

      try {

        if (this.status == 1) {
          this.result.title = `Thank You`
          this.result.desc = `Your application has been received.`
        } else if (this.status == 4) {
          this.result.title = `Incorrect cover letter file type.`
          this.result.desc = `Unable to complete the application, please try again.`
        } else if (this.status == 5) {
          this.result.title = `Incorrect resume file type.`
          this.result.desc = `Unable to complete the application, please try again.`
        } else {
          this.result.title = `Looks like something went wrong.`
          this.result.desc = `Unable to complete the application, please try again.`
        }
      } catch (err) {
        // no position found, redirect to DeepSea home
        this.$router.push('/deepsea')
      }
  },
  methods: {
    // setTestTimeout() {
    //   setTimeout(() => {
    //     this.response_loading = true
    //   }, 2000)
    // }
  }
}
</script>

<style scoped>
div.deepsea-response-msg {
  height: -webkit-fill-available; /* TESTING */
  background-image: linear-gradient(
      hsla(0, 0%, 96%, 1) 10%,
      hsla(0, 0%, 100%, 0.8)
    ),
    url('../assets/backgrounds/BackgroundDeepSeaSuccessPg.jpg');
  /* background: inherit; */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8%;
}
div.deepsea-response-msg p {
  padding-top: 1em;
  padding-bottom: 24px;
}
.deepsea-response-btn {
  width: 272px;
}
</style>