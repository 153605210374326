<template>
  <div class="separator" :style="{ borderColor: color }"></div>
</template>

<script>
export default {
  name: 'Separator',
  props: {
    color: String
  }
}
</script>

<style scoped>
.separator {
  border-bottom-style: solid;
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #5bba47; /* default color */
  border-top-width: 4px;
  height: auto;
  margin: 0 auto 4% auto;
  max-width: 50px;
  width: 100%;
}
</style>
