<template>
  <div class="header">
    <div class="title-heading-left">
      <h1>{{ title }}</h1>
    </div>
    <div class="header-bg">
      <img :src='imgPath' alt="header-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    img: String
  },
  data() {
    return {
      title: 'Job Opportunities',
    }
  },
  computed: {
    imgPath() {
      return require(`../assets/images/${this.img}`)
    }
  }
}
</script>

<style>
/* Mobile First Default */
.header {
  padding: 0;
  position: relative;
  width: 100%;
  height: auto;
}
.header .title-heading-left {
  display: block;
  padding: 0px 25px;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  max-width: 100vw;
  z-index: 5;
}
.header .title-heading-left h1 {
  color: #ffffff;
  display: inline-block;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px 15px;
  width: 100%;
}
.header-bg {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}
.header-bg img {
  margin-top: -2px;
  max-width: 100%;
  width: 100%;
  height: auto;
}
/* .header-bg-img {
  background-image: url('../assets/images/header-seescan.png');
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: auto auto;
  margin-top: -2px;
  height: 953px;
  max-height: 953px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
} */
@media screen and (min-width: 645px) {
  .header .title-heading-left {
    padding: 0px 50px;
  }
  .header .title-heading-left h1 {
    padding: 5px 15px;
  }
}
</style>
