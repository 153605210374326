<template>
  <button class="gradient-button" :class="[ deepsea ? 'deepsea-bg': 'seescan-bg' ]">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    deepsea: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
/* Mobile First Implementation */
.deepsea-bg {
  background: linear-gradient(to bottom, #192b6d 0%, #21409a 100%);
}
.deepsea-bg:hover {
  background: #ffda00;
  border: none;
  color: black;
}
.seescan-bg {
  background: linear-gradient(to bottom, #5bba47 0%, #6dd400 100%);
}
.seescan-bg:hover {
  background: black;
  border: none;
}
.gradient-button {
  /* background: linear-gradient(to bottom, #5bba47 0%, #6dd400 100%); */
  border-radius: 25px;
  border: none;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 2px;
  margin: 3%;
  padding: 14px 40px;
  text-align: center;
  text-transform: uppercase;
  width: 250px;
}
/* .gradient-button:hover {
  background: #000000;
  border: none;
} */
@media only screen and (min-width: 576px) {
  .gradient-button {
    width: fit-content;
  }
}
</style>
