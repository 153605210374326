<!-- SeeScan Positions Pg -->
<template>
  <!-- Intro -->
  <div class="intro">
    <br>
    <br>
    <div class="swarn">
      <p>
        <strong>SCAM ALERT: Be aware of Fake Recruiters</strong>
        <br>
        SeeScan, Inc. has become aware that someone posing as a recruiter from "<strong>seescancareershub.com</strong>" is reaching out to job seekers. Please be aware that this domain, "<strong>seescancareershub.com,</strong>" is not affiliated with our company domain "<strong>seescan.com</strong>" in any way. If you are interested in applying for a job at SeeScan, Inc., the official website is <a class="text-link" href="https://careers.seescan.com/"><strong>https://careers.seescan.com</strong></a>. For your safety and to ensure you are communicating with SeeScan's recruiters, please verify the domain of the email address. Official communications from SeeScan, Inc. will only come from email addresses ending in "<strong>@seescan.com.</strong>"
    <br>
Thank you for your vigilance and cooperation.
    </p>
    </div>
    <br>
    <p>
      Welcome to SeeScan’s job opportunities portal. We’re committed to creating
      a collaborative and inspiring culture for everyone who works here. All of
      our currently open positions are listed below by category and include
      positions at our SeeScan and DeepSea divisions. Select a position to view
      more information and to apply.
    </p>
    <br>
    <a
      href="#who-we-hire"
      target="_self"
      rel="noopener noreferrer"
    >
      <Button>Who We Hire</Button>
    </a>
    <br>
    <br>
    <p>
      Visit our subsea division
      <router-link
        class="text-link"
        :to="{
          name: 'deepsea-positions'
        }"
        @click="setFavicon"
      >
        <strong>DeepSea</strong>
      </router-link>
      jobs page to view additional job opportunities.
    </p>
  </div>

  <!-- Separator -->
  <BaseBackground
    background-name="background-half-circle"
    background-color="#e8e8e8"
  >
    <BackgroundHalfCircle />
  </BaseBackground>

  <!-- Current Openings -->
  <div class="current-openings">
    <h2>Current Openings</h2>
    <Separator :color="'black'" />
    <p v-if="pos_loading">
      Loading...
    </p>
    <div class="job-wrap">
      <div class="text-buttons">
        <TextButton
          v-show="collapsed"
          @click="showAll()"
        >
          View All
        </TextButton>
        <TextButton
          v-show="!collapsed"
          @click="hideAll()"
        >
          Hide All
        </TextButton>
      </div>
      <details
        v-for="(key, val) in positions"
        :key="val"
      >
        <summary>{{ val }} ({{ key.count }})</summary>
        <ul>
          <li
            v-for="position in key.positions"
            :key="position"
          >
            <router-link
              :to="{
                name: 'position-description',
                params: { id: position.id }
              }"
            >
              {{ position.title }}
            </router-link>
            <hr>
          </li>
        </ul>
      </details>
    </div>
    <p v-if="pos_error">
      {{ pos_error }}
    </p>

    <!--
    <router-link :to="{ name:'applicant-form', params:{ id: 1 }}">
      <Button id="apply-now">Apply Now</Button>
    </router-link>
    <br>
    -->

    <br><br>
    <a
      href="#cover-letter"
      target="_self"
      rel="noopener noreferrer"
    >
      <TextButton>The Importance of the Cover Letter</TextButton>
      <!-- <Button>The Importance of the Cover Letter</Button> -->
    </a>
  </div>

  <!-- Who We Hire -->
  <div
    id="who-we-hire"
    class="who"
  >
    <h2 class="white">
      Who We Hire
    </h2>
    <Separator :color="'black'" />
    <br>
    <p style="font-size:1.05em; padding-bottom: .2em;">
      <strong>No matter what position we’re hiring for, we’re looking for people who are:</strong>
    </p>
    <br>
    <div class="who-grid">
      <p>
        <strong>In it for work that matters</strong> – <br>Our goal is to make
        innovative technology that makes the world better. When our employees
        believe in our mission and express a genuine desire to be part of what we do, we achieve our best work. We want people who will view being here as more than “just another job.”
      </p>
      <p>
        <strong>Community member</strong> – <br>SeeScan is a people-focused
        organization, and at the heart of SeeScan is our community. We’re in it
        for the long run. We want people who will be part of the larger community and stay with us for the long run, too.
      </p>
      <p>
        <strong>Authentically curious, critical thinker</strong> – <br>SeeScan is a learning organization. In fact, continual learning is one of our core values. Our commitment to continuously improving as an organization means we hire people who demonstrate both the desire and capability to continually learn and grow themselves. This translates to hiring people who are naturally curious at heart, who not only do their jobs well but are able to imagine and do better individually and across the organization.
      </p>
      <p>
        <strong>Independently driven with a “we” mentality</strong> – <br>At SeeScan, we have an open, collaborative environment instead of rigid hierarchies. This translates to a lot of autonomy for many of our employees. But regardless of the degree of autonomy a single employee has, what ties all of us together is being internally driven to succeed. At the same time, while our employees are motivated to do their best at their own work, they are always thinking of the wider team and organization.
      </p>
      <p class="padding-bottom">
        <strong>Aligned with our values</strong> – <br>Fairness, integrity, respect, transparency. These are the values we live at SeeScan, and our candidates should have these, too.
      </p>
    </div>
    <br>
  </div>

  <!-- Cover Letter -->
  <div
    id="cover-letter"
    class="cover-letter"
  >
    <h2>The Importance of the Cover Letter</h2>
    <Separator :color="'black'" />
    <p>
      <strong>At SeeScan, our focus is on hiring the person, not a list of
        qualifications. Hiring the person—you!—starts with the cover
        letter.</strong>
    </p>
    <br>
    <p>
      We require all of our candidates to submit a thoughtful cover letter in
      addition to their resume. The cover letter is our first introduction to
      you as a person. We are interested in knowing more about you, why you want
      to be part of the SeeScan team, and how you think your skills and
      experiences will positively contribute to our organization.
    </p>
    <br>
    <p>
      The cover letter is so important to us that only candidates who submit a
      cover letter with their application will be considered for employment at
      SeeScan.
    </p>
    <br>
    <p>
      The cover letters that we are looking for:
      <ul>
        <li>Express a genuine interest in working here</li>
        <li>
          Highlight your unique skills and background, and describe how they will help you succeed in the role based on what the job description calls for
        </li>
        <li>
          Remain on message and free of inconsistencies with your resume or application
        </li>
        <li>
          Are generally formatted well
        </li>
      </ul>
    </p>
    <br>
    <p>
      If your cover letter is a match for what we’re looking for, we will reach out to you for a phone interview. You can read more about our 3-step hiring process on our <a
        class="text-link"
        href="https://www.seescan.com/life-at-seescan/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>Life at SeeScan</strong>
      </a> page.
    </p>
  </div>
</template>

<script>
import axios from 'axios'
import BackgroundHalfCircle from '@/components/backgrounds/BackgroundHalfCircle.vue'
import BaseBackground from '@/components/global/BaseBackground.vue'
import Button from '@/components/buttons/Button.vue'
// import LearnMore from '@/components/LearnMore.vue'
import Separator from '@/components/Separator.vue'
import TextButton from '@/components/buttons/TextButton.vue'

let positionsUrl =
  'https://careers-api.seescan.com/api/v1/positions/division/seescan'
// let positionsUrl = 'http://localhost:3000/api/v1/positions'
const posRequest = axios.get(positionsUrl)

export default {
  name: 'Positions',
  components: {
    BackgroundHalfCircle,
    BaseBackground,
    Button,
    // LearnMore,
    Separator,
    TextButton
  },
  data() {
    return {
      pos_loading: false,
      positions: null,
      pos_error: '',
      collapsed: true
    }
  },
  created() {
    axios
      .all([posRequest])
      .then(
        axios.spread((...responses) => {
          const responsePos = responses[0].data
          let categories = {}

          for (let i = 0; i < responsePos.data.positions.results.length; i++) {
            let cat = responsePos.data.positions.results[i].category
            let position = {
              id: responsePos.data.positions.results[i].id,
              title: responsePos.data.positions.results[i].title
            }

            if (Object.prototype.hasOwnProperty.call(categories, cat)) {
              categories[cat].positions.push(position)
              categories[cat].count++
              if (categories[cat].count > 1) {
                categories[cat].label = 'Jobs'
              }
            } else {
              categories[cat] = {
                count: 1,
                label: 'Job',
                positions: []
              }
              categories[cat]['count'] = 1
              categories[cat].positions.push(position)
            }
          }

          this.positions = categories
        })
      )
      .catch(errors => {
        console.error(errors)
      })
  },
  methods: {
    setFavicon(to) {
      const favicon = document.querySelector("[rel='icon']")
      favicon.setAttribute('href', to.$route.meta.icon)
    },
    showAll() {
      // Fetch all details elements
      const details = document.querySelectorAll('details')

      // Open all the details
      details.forEach(detail => {
        detail.open = true
      })

      this.collapsed = false
    },
    hideAll() {
      // Fetch all details elements
      const details = document.querySelectorAll('details')

      // Close all the details
      details.forEach(detail => {
        detail.open = false
      })

      this.collapsed = true
    }
  }
}
</script>

<style scoped>
/* Mobile first default */
h2 {
  color: rgb(92, 186, 71);
}
h2.white {
  color: white;
  padding-top: 8%;
}
ul li {
  list-style: none;
}
a.text-link {
  color: black;
  font-size: 1em;
}
/* Intro */
.intro {
  padding: 8%;
}
div.swarn {
  border: 2px solid #5bba47;
  border-radius: 30px;
  box-sizing: border-box;
}

div.swarn p {
  padding: .5em;
}

/* Current Openings */
.current-openings {
  background-color: #e8e8e8;
  padding: 8% 8% 12% 8%;
  margin-bottom: -12px;
}
div.job-wrap {
  width: 100%;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
}
div.job-wrap div.text-buttons {
  display: flex;
  flex: row nowrap;
  justify-content: center;
}
details {
  margin: 0 auto;
  cursor: pointer;
  padding: 1em;
  background: #f0f0f0;
  max-width: 800px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  font-weight: normal;
  color: #464545;
  border-radius: 16px;
  border: none;
  margin-top: 16px;
  box-shadow: inset 0px -11px 8px -10px #ccc;
}
details summary::-webkit-details-marker {
  margin-top: 10px;
  color: #6dd400;
  float: right;
}
details:hover summary::-webkit-details-marker:hover {
  color: #5cba47;
}
details summary {
  outline: none;
  /* font-size: 22px; */
  color: #444444;
}
details summary > * {
  display: inline;
}
details ul {
  margin: 0;
  padding: 1em;
  text-align: left;
}
details ul li:last-child > hr {
  display: none;
}
details ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
details ul li {
  margin: 0;
  padding: 0;
}
details ul li a {
  color: #666666;
}
details summary ul li a:hover {
  text-decoration: none;
}

/* #apply-now {
  margin-bottom: 32px;
  margin-top: 24px;
} */

/* Who We Hire */
.who {
  padding: 8% 8% 0% 8%;
  background-image: url('../assets/backgrounds/BackgroundGradient.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.who .who-grid p {
  text-align: left;
  padding-bottom: 2em;
}
.who p.padding-bottom {
  margin-bottom: 300px;
}
/* Cover Letter */
.cover-letter {
  margin-top: -150px;
  padding: 0% 8% 8% 8%;
}
.cover-letter, 
.cover-letter p {
  text-align: left;
}
.cover-letter ul {
  margin: 1em 2em;
}
.cover-letter ul li {
  list-style-position: outside;
  list-style-type: square;
  margin-bottom: 4px;
}

@media screen and (min-width: 615px) {
  div.swarn {
    border: 2px solid #5bba47;
    border-radius: 30px;
    margin: 0;
  }

  h2.white {
    padding-top: 4%;
  }
  .current-openings {
    margin-bottom: -55px;
    padding-bottom: 14%;
  }
  .who {
    background-size: auto 100%;
  }
  .who p.padding-bottom {
    padding-bottom: 50px;
  }
  .cover-letter {
    margin-top: -75px;
  }
  .hiring-process {
    position: relative;
    z-index: -99;
    top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1200px) {
  h2.white {
    padding-top: 0;
  }
  p {
    padding: 0 16%;
  }
  .intro {
    padding: 8% 8% 5% 8%;
  }
  .current-openings {
    margin-bottom: -160px;
    padding: 0% 8% 300px 8%;
  }
  /** Who We Hire */
  .who {
    background-size: 100% 100%;  
    padding: 8% 12% 0% 12%;
  }
  .who p {
    padding: 0%;
  }
  .who-grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0px 36px;
  }
  .cover-letter {
    margin-top: -25px;
  }
  .cover-letter ul {
    margin: 1em 19%;
  }
  div.job-wrap div.text-buttons {
    justify-content: flex-end;
  }

  div.swarn {
    border: 0;
    box-sizing: border-box;
    padding: 0 16%;
  }

div.swarn p {
  border: 2px solid #5bba47;
  border-radius: 30px;
}

}

@media screen and (min-width: 1950px) {
  p {
    padding: 0% 24%;
  }
  .separator {
    margin: 0 auto;
    padding-bottom: 48px;
  }
  .intro {
    padding: 4% 8% 2% 8%;
  }
  .current-openings {
    padding: 0% 8% 320px 8%;
  }
  .who {
    padding: 8% 24% 0% 24%;
  }
  .who #who-first-p {
    padding-left: 4%;
  }
  .who-grid {
    padding: 0% 4%;
  }
  .cover-letter ul {
    margin: 1em 26%;
  }

  div.swarn {
    border: 0;
    box-sizing: border-box;
    padding: 0 24%;
  }

  div.swarn p {
    border: 2px solid #5bba47;
    border-radius: 30px;
  }

}
</style>
