<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 100 100"
    :aria-labelledby="backgroundName"
    role="presentation"
    preserveAspectRatio="none"
  >
    <title :id="backgroundName" lang="en">
      {{ backgroundName }} background
    </title>

    <g :fill="backgroundColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    backgroundName: {
      type: String,
      default: 'base'
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    backgroundColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style scoped>
/* Mobile First Implementation */
svg {
  display: block;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  transform: scale(1, 1);
  max-height: 20px;
}
@media screen and (min-width: 400px) {
  svg {
    max-height: 40px;
  }
}
@media screen and (min-width: 600px) {
  svg {
    max-height: 60px;
  }
}
@media screen and (min-width: 835px) {
  svg {
    max-height: 80px;
  }
}
@media screen and (min-width: 1200px) {
  svg {
    max-height: 200px;
  }
}
</style>
