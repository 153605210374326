<template>
  <div class="mobile-nav">
    <!-- DeepSea Mobile Nav -->
    <nav class="deepsea-nav" v-if="deepsea">
      <!-- START DeepSea -->
      <ul>
        <!-- DeepSea Home -->
        <li><a href="http://www.deepsea.com">Home</a></li>
        <!-- Our Company - Dropdown -->
        <li class="dropdown" @click="showCoDropdown = !showCoDropdown">
          <div class="dropdown-li">
            <a href="#">Our Company</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- Our Company - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showCoDropdown">
            <li>
              <a href="https://www.deepsea.com/about-us/"> About Us </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/our-history/"> Our History </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/our-campus/"> Our Campus </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/terms-and-conditions/">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/intellectual-property/">
                Intellectual Property
              </a>
            </li>
          </ul>
        </transition>
        <!-- Our Products - Dropdown -->
        <li
          class="dropdown"
          @click="showProductsDropdown = !showProductsDropdown"
        >
          <div class="dropdown-li">
            <a href="#">Our Products</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- Our Products - Dropdow Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showProductsDropdown">
            <li>
              <a href="https://www.deepsea.com/products/#IP-Cameras">
                Cameras
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#LED-Lights">
                Lights
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Lasers"> Lasers </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Relief-Valves">
                Relief Valves
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Batteries"> Power </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Accessories">
                Accessories
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Technology">
                Technology
              </a>
            </li>
          </ul>
        </transition>
        <!-- DeepSea Services - Dropdown -->
        <li
          class="dropdown"
          @click="showServicesDropdown = !showServicesDropdown"
        >
          <div class="dropdown-li">
            <a href="#">Services</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- DeepSea Services - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showServicesDropdown">
            <li>
              <a href="https://www.deepsea.com/services/#pressure">
                Pressure Testing
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/services/#optical">
                Optical Measurement
              </a>
            </li>
          </ul>
        </transition>
        <!-- DeepSea Careers - Dropdown -->
        <li
          class="dropdown"
          @click="showCareersDropdown = !showCareersDropdown"
        >
          <div class="dropdown-li">
            <a href="#">Careers</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- DeepSea Careers - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showCareersDropdown">
            <li>
              <a href="https://www.deepsea.com/life-at-deepsea/">
                Life at DeepSea
              </a>
            </li>
            <li>
              <router-link to="/deepsea" :active="isActive" @click="setFavicon"
                >Job Opportunities</router-link
              >
            </li>
          </ul>
        </transition>
        <!-- DeepSea Knowledgebase -->
        <li
          class="dropdown"
          @click="showKnowledgebaseDropdown = !showKnowledgebaseDropdown"
        >
          <div class="dropdown-li">
            <a href="https://www.deepsea.com/knowledgebase/">Knowledgebase</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- DeepSea Knowledgebase - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showKnowledgebaseDropdown">
            <li>
              <a
                href="https://www.deepsea.com/knowledgebase/#underwater-imaging"
                >Underwater Imaging</a
              >
            </li>
            <li>
              <a
                href="https://www.deepsea.com/knowledgebase/#underwater-lighting"
                >Underwater Lighting</a
              >
            </li>
            <li>
              <a
                href="https://www.deepsea.com/knowledgebase/#technical-resources"
                >Technical Resources</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/knowledgebase/#products-in-use"
                >Products In Use</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/knowledgebase/#design-tools"
                >Design Tools</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/knowledgebase/#pressure-testing"
                >Pressure Testing</a
              >
            </li>
          </ul>
        </transition>
        <!-- DeepSea Contact Us - Dropdown -->
        <li
          class="dropdown"
          @click="showContactDropdown = !showContactDropdown"
        >
          <div class="dropdown-li">
            <a href="#">Contact Us</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- DeepSea Contact Us - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showContactDropdown">
            <li>
              <a href="https://www.deepsea.com/contact-us/#sales">
                Contact Sales
              </a>
            </li>
            <li
              class="dropdown"
              @click="showOurRepsDropdown = !showOurRepsDropdown"
            >
              <div class="dropdown-li">
                <a
                  href="https://www.deepsea.com/contact-us/#our-representatives"
                >
                  Our Representatives
                </a>
                <button class="dropdown-icon">
                  <BaseIcon :iconName="dropdown">
                    <IconDropdown />
                  </BaseIcon>
                </button>
              </div>
            </li>
            <transition name="dropdown">
              <ul class="dropdown-links level-3" v-if="showOurRepsDropdown">
                <li>
                  <a href="https://www.deepsea.com/asia/">Asia</a>
                </li>
                <li>
                  <a href="https://www.deepsea.com/australia/">Australia</a>
                </li>
                <li>
                  <a href="https://www.deepsea.com/canada/">Canada</a>
                </li>
                <li>
                  <a href="https://www.deepsea.com/europe/">Europe</a>
                </li>
                <li>
                  <a href="https://www.deepsea.com/middle-east/">Middle East</a>
                </li>
                <li>
                  <a href="https://www.deepsea.com/south-america/"
                    >South America</a
                  >
                </li>
                <li>
                  <a href="https://www.deepsea.com/united-states/"
                    >United States</a
                  >
                </li>
              </ul>
            </transition>
          </ul>
        </transition>
        <!-- DeepSea Support - Dropdown -->
        <li
          class="dropdown"
          @click="showSupportDropdown = !showSupportDropdown"
        >
          <div class="dropdown-li">
            <a href="#">Support</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- DeepSea Support - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showSupportDropdown">
            <li
              class="dropdown"
              @click="showProductDocumentation = !showProductDocumentation"
            >
              <div class="dropdown-li">
                <a href="https://www.deepsea.com/product-documentation/">
                  Product Documentation
                </a>
                <button class="dropdown-icon">
                  <BaseIcon :iconName="dropdown">
                    <IconDropdown />
                  </BaseIcon>
                </button>
              </div>
            </li>
          </ul>
        </transition>
        <!-- DeepSea Product Documentation - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links level-3" v-if="showProductDocumentation">
            <li>
              <a href="https://www.deepsea.com/product-documentation/cameras/"
                >Cameras</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/product-documentation/lights/"
                >Lights</a
              >
            </li>
            <li>
              <a
                href="https://www.deepsea.com/product-documentation/relief-valves/"
                >Relief Valves</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/product-documentation/batteries/"
                >Batteries</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/product-documentation/lasers/"
                >Lasers</a
              >
            </li>
            <li>
              <a
                href="https://www.deepsea.com/product-documentation/technology/"
                >Technology</a
              >
            </li>
          </ul>
        </transition>
      </ul>
      <!-- END DeepSea -->
    </nav>
    <!-- SeeScan Mobile Nav -->
    <nav class="seescan-nav" v-else>
      <!-- START SeeScan -->
      <ul>
        <!-- SeeScan Home -->
        <li><a href="http://www.seescan.com">Home</a></li>
        <!-- Our Company - Dropdown -->
        <li class="dropdown" @click="showCoDropdown = !showCoDropdown">
          <div class="dropdown-li">
            <a href="#">Our Company</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- Our Company - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showCoDropdown">
            <li>
              <a href="https://www.seescan.com/about-us/"> About Us </a>
            </li>
            <li>
              <a href="https://www.seescan.com/our-history/"> Our History </a>
            </li>
            <li>
              <a href="https://www.seescan.com/our-campus/"> Our Campus </a>
            </li>
            <li>
              <a href="https://www.seescan.com/terms-and-conditions/">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/intellectual-property/">
                Intellectual Property
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/covid-19/"> COVID-19 </a>
            </li>
          </ul>
        </transition>
        <!-- SeeScan Careers - Dropdown -->
        <li
          class="dropdown"
          @click="showCareersDropdown = !showCareersDropdown"
        >
          <div class="dropdown-li">
            <a href="#">Careers</a>
            <button class="dropdown-icon">
              <BaseIcon :iconName="dropdown">
                <IconDropdown />
              </BaseIcon>
            </button>
          </div>
        </li>
        <!-- SeeScan Careers - Dropdown Submenu -->
        <transition name="dropdown">
          <ul class="dropdown-links" v-if="showCareersDropdown">
            <li>
              <a href="https://www.seescan.com/life-at-seescan/">
                Life at SeeScan
              </a>
            </li>
            <li>
              <router-link to="/" :active="isActive" @click="setFavicon"
                >Job Opportunities
              </router-link>
            </li>
          </ul>
        </transition>
        <!-- SeeScan Knowledgebase -->
        <li>
          <a href="https://www.seescan.com/knowledgebase/">Knowledgebase</a>
        </li>
        <!-- SeeScan Geo Locating -->
        <li>
          <a href="https://www.seescan.com/geo-locating/">Geo Locating</a>
        </li>
        <!-- SeeScan Our Products -->
        <li><a href="https://www.seescan.com/products/">Our Products</a></li>
        <!-- SeeScan Repair -->
        <li><a href="https://www.seescan.com/repair/">Repair</a></li>
        <!-- SeeScan Contact Us -->
        <li><a href="https://www.seescan.com/contact_us/">Contact Us</a></li>
      </ul>
      <!-- END SeeScan -->
    </nav>
  </div>
</template>

<script>
import BaseIcon from './global/BaseIcon.vue'
import IconDropdown from './icons/IconDropdown.vue'
export default {
  name: 'Mobile Nav',
  components: {
    BaseIcon,
    IconDropdown,
  },
  props: {
    deepsea: {
      type: Boolean,
    },
  },
  data() {
    return {
      showCareersDropdown: false,
      showCoDropdown: false,
      showContactDropdown: false,
      showKnowledgebaseDropdown: false,
      showOurRepsDropdown: false,
      showProductDocumentation: false,
      showProductsDropdown: false,
      showServicesDropdown: false,
      showSupportDropdown: false,
    }
  },
  methods: {
    setFavicon() {
      const favicon = document.querySelector("[rel='icon']")
      favicon.setAttribute('href', this.$route.meta.icon)
    },
  },
}
</script>

<style scoped>
.mobile-nav {
  color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 95px;
  left: 0px;
  width: 100vw;
  margin: 0 auto;
  overflow: visible;
  z-index: 100;
  text-align: left;
}
.deepsea-nav {
  background: #192b69;
}
.seescan-nav {
  background: #000000;
}
nav ul {
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  margin: 0;
}
nav ul li {
  display: inline-block;
  width: 100vw;
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid;
  cursor: pointer;
  padding-left: 30px;
}
nav.deepsea-nav ul li:hover,
nav.deepsea-nav ul li:active {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}
nav.seescan-nav ul li:hover,
nav.seescan-nav ul li:active {
  background: rgba(20, 20, 20, 0.8);
  border-color: rgba(255, 255, 255, 0.2);
}
nav ul li a {
  display: block;
  color: white;
  height: 48px;
  line-height: 48px;
}
nav.deepsea-nav ul li:hover a,
nav.deepsea-nav ul li:active a {
  color: #ffda00;
}
nav.seescan-nav ul li:hover a,
nav.seescan-nav ul li:active a {
  color: #5bba47;
}
nav ul li:hover a,
nav ul li:active a {
  background: transparent;
  border-top-style: none;
  text-decoration: none;
}
/* dropdown */
nav ul li.dropdown div.dropdown-li {
  display: flex;
  flex-basis: auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}
button.dropdown-icon {
  color: #f2f2f2;
  height: 16px;
  background: transparent;
  border: none;
}
/* nav ul li:hover button.dropdown-icon,
nav ul li:hover button.dropdown-icon:hover {
  background: transparent;
  border: none;
} */
ul.dropdown-links {
  border-top: none;
}
ul.dropdown-links li {
  padding-left: 50px;
}
ul.level-3 li {
  padding-left: 70px;
}
</style>
