<template>
  <div class="search-bar">
    <form
      role="search"
      class="search-form"
      method="get"
      action="https://www.deepsea.com/"
    >
      <div class="search-content">
        <div class="search-field">
          <label>
            <span class="search-for">Search for:</span>
            <input
              type="search"
              value
              name="s"
              class="s"
              placeholder="Search..."
              required
              aria-required="true"
              aria-label="Search..."
            />
          </label>
        </div>
        <div class="search-btn">
          <input
            type="submit"
            class="search-btn-icon"
            aria-label="search"
            value="Search"
          />
          <font-awesome-icon
            class="icon-search"
            icon="magnifying-glass"
            color="#aaa9a9"
          />
        </div>
      </div>

      <input type="hidden" name="lang" value="en" />
    </form>
  </div>
</template>

<script>
export default {}
</script>

<style>
div.search-btn .icon-search {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
}
input[type='submit'].search-btn-icon {
  z-index: 1000;
  position: relative;
  width: 29px;
  height: 29px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: button;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  display: inline-block;
  background-image: none;
}
div.search-content div.search-btn {
  color: #747474;
  font-weight: 400;
  position: absolute;
  left: 0;
}
input.s {
  background: #ffffff;
  padding-left: 29px;
  padding-right: 15px;
  border-width: 1px 1px 1px 1px;
  border-color: #d2d2d2;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
div.search-field span.search-for {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
div.search-field {
  flex-grow: 1;
}
div.search-bar form.search-form div.search-content {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 29px;
}
div.search-bar form.search-form {
  margin: 0;
  padding: 0;
  border-style: none;
  font-weight: 400;
}
div.search-bar {
  background: #192b6d;
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.2);
  padding: 25px;
  color: #747474;
  position: absolute;
  top: 105px;
  left: auto;
  right: 30px;
  width: 250px;
  z-index: 100;
}
</style>
