<template>
  <div class="seescan-response-msg">
    <h2>{{ result.title }}</h2>
    <Separator />
    <p>{{ result.desc }}</p>
    <!-- Success Btn -->
    <a href="https://www.seescan.com" v-if="this.status == 1">
      <Button class="seescan-response-btn">Explore SeeScan</Button>
    </a>
    <!-- Redirect Btn -->
    <router-link to="/" v-else>
      <Button class="seescan-response-btn">SeeScan Jobs</Button>
    </router-link>
  </div>

  <!-- <Dots v-if="response_loading" /> -->

  <!-- <Suspense>
    <template #default>
      <div class="success-msg">
        <h2>{{ result.title }}</h2>
        <Separator />
        <p>{{ result.desc }}</p>
        <a href="https://www.seescan.com">
          <Button class="button">Explore SeeScan</Button>
        </a>
      </div>
    </template>-->

  <!-- <template #fallback>
      Test Loading...
      <Dots />
    </template>
  </Suspense> -->
</template>

<script>

import Separator from '@/components/Separator.vue'
import Button from '@/components/buttons/Button.vue'
// import Dots from '@/components/loaders/Dots.vue'
// import { ref } from 'vue'

export default {
  name: 'response',
  components: {
    Separator,
    Button,
    // Dots
  },
  data() {
    return {
      response_loading: false,
      //position: {
      //  title: [],
      //},
      pos_error: '',
      //posId: null,
      status: null,
      result: {
        title: null,
        desc: null,
      },
    }
  },
  created: function () {
    this.status = this.$route.params.status

      try {

        if (this.status == 1) {
          this.result.title = `Thank You`
          this.result.desc = `Your application has been received.`
        } else if (this.status == 4) {
          this.result.title = `Incorrect cover letter file type.`
          this.result.desc = `Unable to complete the application, please try again.`
        } else if (this.status == 5) {
          this.result.title = `Incorrect resume file type.`
          this.result.desc = `Unable to complete the application, please try again.`
        } else {
          this.result.title = `Looks like something went wrong.`
          this.result.desc = `Unable to complete the application, please try again.`
        }
      } catch (err) {
        // no position found, redirect to SeeScan home
        this.$router.push('/')
      }
  },
  methods: {
    // setTestTimeout() {
    //   setTimeout(() => {
    //     this.response_loading = true
    //   }, 2000)
    // }
  },
}
</script>

<style scoped>
div.seescan-response-msg {
  background-image: linear-gradient(
      hsla(0, 0%, 96%, 1) 10%,
      hsla(0, 0%, 100%, 0.8)
    ),
    url('../assets/backgrounds/BackgroundSuccessPg.jpg');
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 8%;
}
div.seescan-response-msg p {
  padding-top: 1em;
  padding-bottom: 24px;
}
.seescan-response-btn {
  width: 272px;
}
</style>
