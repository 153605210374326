<template>
  <button class="text-button" :style="setHoverColor">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Text Button',
  props: {
    hoverColor: {
      type: String,
      default() {
        return '#5bba47' // default hover color
      }
    }
  },
  data() {
    return {
      button: {
        borderColorHover: this.hoverColor
      }
    }
  },
  computed: {
    setHoverColor() {
      return {
        '--button-border-bottom-color--hover': this.button.borderColorHover
      }
    }
  }
}
</script>

<style scoped>
button:hover {
  border-bottom-color: var(--button-border-bottom-color--hover);
}
.text-button {
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
  background: none;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0.2em;
}
</style>
