<template>
  <v-app>
    <!-- Navigation -->
    <DeepSeaNav v-if="isDeepSea" />
    <SeeScanNav v-else />

    <!-- Header -->
    <Header v-if="isDeepSea" :img="'header-deepsea.png'" />
    <Header v-else :img="'header-seescan.png'" />

    <v-main>
      <router-view />
    </v-main>

    <!-- Footer -->
    <Footer v-if="isDeepSea" :deepsea="true" />
    <Footer v-else />
  </v-app>
</template>

<script>
import DeepSeaNav from '@/components/DeepSeaNav.vue'
import SeeScanNav from '@/components/SeeScanNav.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    DeepSeaNav,
    SeeScanNav,
    Header,
    Footer,
  },
  computed: {
    isDeepSea() {
      if (this.$route.name == 'deepsea-positions') {
        return true
      } else if (this.$route.name == 'deepsea-position-description') {
        return true
      } else if (this.$route.name == 'deepsea-applicant-form') {
        return true
      } else if (this.$route.name == 'deepsea-applicant-response') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    addApplicant(applicant) {
      const newApplicant = { ...applicant }
      this.applicants = [...this.applicants, newApplicant]
    },
  },
}
</script>
