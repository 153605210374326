import { createWebHistory, createRouter } from "vue-router"

// SeeScan
import Positions from '@/views/Positions.vue'
import Position from '@/views/Position.vue'
import ApplicantForm from '@/views/ApplicantForm.vue'
import ApplicantResponse from '@/views/Response.vue'

// DeepSea
import DeepSeaPositions from '@/views/DeepSeaPositions.vue'
import DeepSeaPosition from '@/views/DeepSeaPosition.vue'
import DeepSeaApplicantForm from '@/views/DeepSeaApplicantForm.vue'
import DeepSeaApplicantResponse from '@/views/DeepSeaResponse.vue'

import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: "/deepsea",
    name: "deepsea-positions",
    component: DeepSeaPositions,
    meta: {
      title: 'DeepSea Job Opportunities',
      icon: "/favicon-deepsea.ico",
      metaTags: [
        {
          name: 'description',
          content: 'Available DeepSea job opportunities.'
        },
      ]
    },
  },
  {
    path: "/",
    name: "positions",
    component: Positions,
    meta: {
      title: 'SeeScan Job Opportunities',
      icon: "/favicon.ico",
      metaTags: [
        {
          name: 'description',
          content: 'Available SeeScan job opportunities.'
        },
      ]
    },
  },
  {
    path: "/deepsea/position/:id",
    name: "deepsea-position-description",
    component: DeepSeaPosition,
    meta: {
      title: 'DeepSea Position Details',
      icon: "/favicon-deepsea.ico",
      metaTags: [
        {
          name: 'description',
          content: 'DeepSea position details.'
        },
      ]
    },
  },
  {
    path: "/position/:id",
    name: "position-description",
    component: Position,
    meta: {
      title: 'SeeScan Position Details',
      icon: "/favicon.ico",
      metaTags: [
        {
          name: 'description',
          content: 'SeeScan position details.'
        },
      ]
    },
    title: "",
  },
  {
    path: "/deepsea/application/:id",
    name: "deepsea-applicant-form",
    component: DeepSeaApplicantForm,
    meta: {
      title: 'DeepSea Application',
      icon: "/favicon-deepsea.ico",
      metaTags: [
        {
          name: 'description',
          content: 'DeepSea job application form.'
        },
      ]
    },
  },
  {
    path: "/application/:id",
    name: "applicant-form",
    component: ApplicantForm,
    meta: {
      title: 'SeeScan Application',
      icon: "/favicon.ico",
      metaTags: [
        {
          name: 'description',
          content: 'SeeScan job application form.'
        },
      ]
    },
  },
  {
    path: "/deepsea/response/:status",
    name: "deepsea-applicant-response",
    component: DeepSeaApplicantResponse,
    meta: {
      title: 'DeepSea Applications',
      icon: "/favicon-deepsea.ico",
      metaTags: [
        {
          name: 'description',
          content: 'DeepSea job application response.'
        },
      ]
    },
  },
  {
    path: "/response/:status",
    name: "applicant-response",
    component: ApplicantResponse,
    meta: {
      title: 'SeeScan Applications',
      icon: "/favicon.ico",
      metaTags: [
        {
          name: 'description',
          content: 'SeeScan job application response.'
        },
      ]
    },
  },
  {
    path: '/404',
    name: "not-found",
    component: NotFound,
    meta: {
      title: 'Not Found',
      // icon: "/favicon.ico",
      metaTags: [
        {
          name: 'description',
          content: 'Page not found.'
        },
      ]
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      // console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
 },
});

router.beforeEach((to) => {
  // for favicon
  const nearestWithIcon = to.matched.slice().reverse().find(r => r.meta && r.meta.icon);
  const favicon = document.querySelector("[rel='icon']")
  if(nearestWithIcon) {
    favicon.setAttribute('href', nearestWithIcon.meta.icon)
  }
})

// callback runs before every route change, including on page load
router.beforeEach((to, from, next) => {
  // for favicon
  // const nearestWithIcon = to.matched.slice().reverse().find(r => r.meta && r.meta.icon);
  // const favicon = document.querySelector("[rel='icon']")
  
  // if(nearestWithIcon) {
  //   favicon.setAttribute('href', nearestWithIcon.meta.icon)
  // }
  
  // required to get meta title working
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  })
  
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;