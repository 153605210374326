<template>
  <!-- Welcome -->
  <div class="welcome">
    <h2>Welcome</h2>
    <Separator :color="'#ffda00'" />
    <p>
      Thank you for your interest in this position! Please fill in the form
      below and we look forward to receiving your application.
    </p>
  </div>

  <BaseBackground
    background-name="background-half-circle"
    background-color="#192b6d"
  >
    <BackgroundHalfCircle />
  </BaseBackground>

  <DeepSeaDots v-if="response_loading" />

  <div id="deepsea-applicant-form" class="applicant-form">
    <form @submit.prevent="checkForm">
      <!-- Contact Information -->
      <fieldset>
        <legend>Contact Information</legend>
        <!-- Name -->
        <label>Name<span class="asterisk">*</span></label>
        <input
          placeholder="First"
          v-model="applicant.first_name"
          :class="{ 'input-err': errors.includes('First Name required') }"
          type="text"
          @keydown.enter.prevent=""
        />
        <input
          placeholder="Last"
          v-model="applicant.last_name"
          type="text"
          :class="{ 'input-err': errors.includes('Last Name required') }"
          @keydown.enter.prevent=""
        />
        <!-- Email -->
        <label>Email<span class="asterisk">*</span></label>
        <input
          placeholder="Email"
          v-model="applicant.email"
          type="text"
          :class="{ 'input-err': errors.includes('Valid Email required') }"
          @keydown.enter.prevent=""
        />
        <input
          placeholder="Confirm"
          v-model="applicant.confirmEmail"
          type="text"
          :class="{ 'input-err': errors.includes('Emails do not match') }"
          @keydown.enter.prevent=""
        />
        <!-- Phone -->
        <label>Phone<span class="asterisk">*</span></label>
        <input
          placeholder=""
          v-model="applicant.phone"
          type="text"
          :class="{
            'input-err': errors.includes('Valid Phone Number required')
          }"
          @keydown.enter.prevent=""
        />
      </fieldset>

      <!-- Address Information -->
      <fieldset>
        <legend>Address Information</legend>
        <!-- Street -->
        <label>Street<span class="asterisk">*</span></label>
        <input
          placeholder=""
          v-model="applicant.address"
          type="text"
          :class="{ 'input-err': errors.includes('Address required') }"
          @keydown.enter.prevent=""
        />
        <!-- City -->
        <label>City<span class="asterisk">*</span></label>
        <input
          placeholder=""
          v-model="applicant.city"
          type="text"
          :class="{ 'input-err': errors.includes('City required') }"
          @keydown.enter.prevent=""
        />
        <!-- State/Zip Code -->
        <div class="state-zip">
          <!-- State -->
          <div class="state">
            <label for="state">State<span class="asterisk">*</span></label>
            <input
              type="text"
              id="state"
              name="state"
              list="states"
              v-model="applicant.state"
              :class="{ 'input-err': errors.includes('State required') }"
              @keydown.enter.prevent=""
            />
            <datalist id="states">
              <option value="Alabama"></option>
              <option value="Alaska"></option>
              <option value="Arizona"></option>
              <option value="Arkansas"></option>
              <option value="California"></option>
              <option value="Colorado"></option>
              <option value="Connecticut"></option>
              <option value="Delaware"></option>
              <option value="District of Columbia"></option>
              <option value="Florida"></option>
              <option value="Georgia"></option>
              <option value="Hawaii"></option>
              <option value="Idaho"></option>
              <option value="Illinois"></option>
              <option value="Indiana"></option>
              <option value="Iowa"></option>
              <option value="Kansas"></option>
              <option value="Kentucky"></option>
              <option value="Louisiana"></option>
              <option value="Maine"></option>
              <option value="Maryland"></option>
              <option value="Massachusetts"></option>
              <option value="Michigan"></option>
              <option value="Minnesota"></option>
              <option value="Mississippi"></option>
              <option value="Missouri"></option>
              <option value="Montana"></option>
              <option value="Nebraska"></option>
              <option value="Nevada"></option>
              <option value="New Hampshire"></option>
              <option value="New Jersey"></option>
              <option value="New Mexico"></option>
              <option value="New York"></option>
              <option value="North Carolina"></option>
              <option value="North Dakota"></option>
              <option value="Ohio"></option>
              <option value="Oklahoma"></option>
              <option value="Oregon"></option>
              <option value="Pennsylvania"></option>
              <option value="Rhode Island"></option>
              <option value="South Carolina"></option>
              <option value="South Dakota"></option>
              <option value="Tennessee"></option>
              <option value="Texas"></option>
              <option value="Utah"></option>
              <option value="Vermont"></option>
              <option value="Virginia"></option>
              <option value="Washington"></option>
              <option value="West Virginia"></option>
              <option value="Wisconsin"></option>
              <option value="Wyoming"></option>
            </datalist>
          </div>
          <!-- Zip Code -->
          <div>
            <label>Zip<span class="asterisk">*</span></label>
            <input
              placeholder=""
              v-model="applicant.zip"
              type="text"
              :class="{
                'input-err': errors.includes('Valid Zip Code required')
              }"
              @keydown.enter.prevent=""
            />
          </div>
        </div>
      </fieldset>

      <!-- Job Information -->
      <fieldset>
        <legend>Job Information</legend>
        <!-- Position -->
        <label>Position(s)<span class="asterisk">*</span></label>
        <p v-if="pos_loading">Loading...</p>
        <!--<select disabled="disabled" v-model="posId">
          <option
            v-for="position in positions"
            :key="position.id"
            :value="position.id"
          >
            {{ position.title }}
          </option>
        </select>-->
        <div>
          <div>
            <ul v-for="groupPosition, name in groupPos" :key="name">
              <fieldset id="positions">
                <legend>{{ name }}</legend>
                <li v-for="position, idx in groupPosition" :key="position.id">                
                <label >                                  
                  <input name="cbPositions" multiple="true" type="checkbox" :id="idx" :value="position.id" v-model="posId" />
                  {{position.title}}
                </label>
                </li>
              </fieldset>
              <!-- <label >{{name}}</label>
              <li v-for="position, idx in groupPosition" :key="position.id">                
                <label >                                  
                  <input name="cbPositions" multiple="true" type="checkbox" :id="idx" :value="position.id" v-model="posId" />
                  {{position.title}}
                </label>
              </li> -->
            </ul>
          </div>
        </div> 
        <p v-if="pos_error">{{ pos_error }}</p>
        <!-- Referred By -->
        <label>Referred By</label>
        <p v-if="ref_loading">Loading...</p>
        <select
          id="ref"
          v-model="applicant.ref_fk"
          :class="{ 'input-err': errors.includes('Referred By required') }"
        >
          <option value="0"> - Select - </option>
          <option
            v-for="referrer in referrers"
            :key="referrer.id"
            :value="referrer.id"
          >
            {{ referrer.title }}
          </option>
        </select>
        <p v-if="ref_error">{{ ref_error }}</p>
      </fieldset>

      <!-- Salary Information-->
      <fieldset>
        <legend>Salary Information</legend>
        <!-- Start/End -->
        <div class="two-col-grid">
          <div>
            <!-- Start -->
            <label>Start</label>
            <input
              placeholder="$"
              v-model="applicant.req_sal_start"
              type="text"
              :class="{
                'input-err': errors.includes('Minimum Salary required')
              }"
              @keydown.enter.prevent=""
            />
          </div>
          <!-- End -->
          <div>
            <label>End</label>
            <input
              placeholder="$"
              v-model="applicant.req_sal_end"
              type="text"
              :class="{
                'input-err': errors.includes('Maximum Salary required')
              }"
              @keydown.enter.prevent=""
            />
          </div>
        </div>

        <!-- Salary Type -->
        <label>Salary Type</label>
        <div class="salary-type">
          <div class="radio-group">
            <input
              v-model="applicant.req_sal_type"
              type="radio"
              id="typeAnnual"
              name="type"
              value="0"
              @keydown.enter.prevent=""
            />
            <label for="typeAnnual">Annual</label>
          </div>
          <div class="radio-group">
            <input
              v-model="applicant.req_sal_type"
              type="radio"
              id="typeHourly"
              name="type"
              value="1"
              @keydown.enter.prevent=""
            />
            <label for="typeHourly">Hourly</label>
          </div>
        </div>
      </fieldset>

      <!-- Additional Information -->
      <fieldset>
        <legend>Additional Information</legend>
        <!-- Question -->
        <label>
          Do you require sponsorship to work in the United States?<span
            class="asterisk"
            >*</span
          >
        </label>
        <div class="sponsorship">
          <div class="radio-group">
            <input
              type="radio"
              id="sponsorshipYes"
              name="sponsorship"
              value="0"
              v-model="applicant.work_us"
              @keydown.enter.prevent=""
            />
            <label for="sponsorshipYes">Yes</label>
          </div>
          <div class="radio-group">
            <input
              type="radio"
              id="sponsorshipNo"
              name="sponsorship"
              value="1"
              v-model="applicant.work_us"
              @keydown.enter.prevent=""
            />
            <label for="sponsorshipNo">No</label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>Privacy</legend>
        <!-- Policy -->
        <p>
          <small>
            The personal information that you submit with this application will only be used for purposes relevant to your employment candidacy. We will not store your personal information for longer than is necessary to consider your candidacy and to comply with all applicable state and federal laws. We do not sell or share your personal information with any third parties.
          </small>
        </p>
        <p>
          <small>     
            To learn more, please visit <a class="privacy-link" href="https://www.seescan.com/privacy" target="_blank">www.seescan.com/privacy</a>.
          </small>
        </p>
      </fieldset>

      <!-- Upload Files -->
      <fieldset class="upload-files">
        <legend>Upload Files</legend>
        <p class="file-types">
          <small>.pdf, .txt, .doc, .docx, .rtf</small>
        </p>
        <!-- Cover Letter -->
        <div class="cover-letter">
          <p>Cover Letter<span class="asterisk">*</span></p>
          <label for="cover-letter"><span>Browse</span></label>
          <input
            id="cover-letter"
            name="cover-letter"
            type="file"
            ref="fileInputCoverLetter"
            accept=".rtf, .txt, .pdf, .doc, .docx"
            @change="onSelectFile('cover-letter')"
            :class="{ 'input-err': errors.includes('Cover Letter required') }"
          />
          <p v-if="applicant.files.cover != null" style="margin-top: -16px;">
            <small> "{{ applicant.files.cover.name }}" </small>
          </p>
          <p v-else style="margin-top: -16px;">
            <small>No file selected</small>
          </p>
        </div>
        <br />
        <!-- Resume -->
        <div class="resume">
          <p>Resume<span class="asterisk">*</span></p>
          <label for="resume"><span>Browse</span></label>
          <input
            id="resume"
            name="resume"
            type="file"
            ref="fileInputResume"
            accept=".rtf, .txt, .pdf, .doc, .docx"
            @change="onSelectFile('resume')"
            :class="{ 'input-err': errors.includes('Resume required') }"
          />
          <p v-if="applicant.files.resume != null" style="margin-top: -16px;">
            <small> "{{ applicant.files.resume.name }}" </small>
          </p>
          <p v-else style="margin-top: -16px;">
            <small>No file selected</small>
          </p>
        </div>
      </fieldset>

      <!-- Errors -->
      <div v-if="errors.length" id="err-summary">
        <strong>Please correct the following error(s):</strong>
        <ul class="error">
          <li v-for="error in errors" :key="error" :value="error">
            {{ error }}
          </li>
        </ul>
      </div>

      <!-- Submit Application-->
      <div @keydown.enter.prevent="">
        <Button :deepsea="true">Apply</Button>
      </div>
      <!-- <button @click="recaptcha">Apply</button> -->
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import DeepSeaDots from '@/components/loaders/DeepSeaDots.vue'
import Button from '@/components/buttons/Button.vue'
import Separator from '@/components/Separator.vue'
import BaseBackground from '@/components/global/BaseBackground.vue'
import BackgroundHalfCircle from '@/components/backgrounds/BackgroundHalfCircle.vue'

// let positionsUrl = 'http://localhost:3000/api/v1/positions/division/deepsea'
// let referrerUrl = 'http://localhost:3000/api/v1/referrer/division/deepsea'

//let positionsUrl = 'https://careers-api.seescan.com/api/v1/positions/division/deepsea'
let referrerUrl = 'https://careers-api.seescan.com/api/v1/referrer'
let groupPosUrl = 'https://careers-api.seescan.com/api/v1/positions/division/deepsea?groupBy=category'

//const posRequest = axios.get(positionsUrl)
const refRequest = axios.get(referrerUrl)
const groupPosRequest = axios.get(groupPosUrl)

export default {
  name: 'deepsea-applicant-form',
  components: {
    Button,
    Separator,
    BaseBackground,
    BackgroundHalfCircle,
    DeepSeaDots
  },
  data() {
    return {
      errorClass: 'error',
      errors: [],
      pos_loading: false,
      ref_loading: false,
      response_loading: false,
      groupPos: null,
      referrers: null,
      pos_error: '',
      ref_error: '',
      posId: [],
      token: null,
      applicant: {
        position_fk: 0,
        ref_fk: 0,
        first_name: '',
        last_name: '',
        email: '',
        confirmEmail: '',
        phone: '',
        address: '',
        city: '',
        state: 'California',
        zip: '',
        req_sal_start: null,
        req_sal_end: null,
        req_sal_type: 0,
        work_us: '',
        division: 'DeepSea',
        files: {
          cover: null,
          resume: null
        }
      },
      submitTimeoutId: null,
    }
  },
  created: function() {
    //this.posId = this.$route.params.id
    this.posId.push(parseInt(this.$route.params.id))
    this.applicant.position_fk = this.$route.params.id

    axios
      .all([refRequest, groupPosRequest])
      .then(
        axios.spread((...responses) => {
          const responseRef = responses[0].data
          const responseGroupPos = responses[1].data

          this.referrers = responseRef.data.referrers.results
          this.groupPos = responseGroupPos.data.positions.results
        })
      )
      .catch(errors => {
        console.error(errors)
      })
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      this.token = token
    },
    uploadFile(event) {
      this.files = event.target.files
    },
    async handleSubmit() {
      var formData = new FormData()
      await this.recaptcha()

      // append properties
      formData.append('position_fk', JSON.stringify(this.posId))
      formData.append('ref_fk', this.applicant.ref_fk)
      formData.append('first_name', this.applicant.first_name)
      formData.append('last_name', this.applicant.last_name)
      formData.append('email', this.applicant.email)
      formData.append('phone', this.applicant.phone)
      formData.append('address', this.applicant.address)
      formData.append('city', this.applicant.city)
      formData.append('state', this.applicant.state)
      formData.append('zip', this.applicant.zip)
      formData.append('req_sal_start', this.applicant.req_sal_start)
      formData.append('req_sal_end', this.applicant.req_sal_end)
      formData.append('req_sal_type', this.applicant.req_sal_type)
      formData.append('work_us', this.applicant.work_us)
      formData.append('division', this.applicant.division)
      formData.append('gRecaptchaResponse', this.token)
      
      // append files
      formData.append('cover', this.applicant.files.cover)
      formData.append('resume', this.applicant.files.resume)

      await axios({
        url: 'https://careers-api.seescan.com/api/v1/applicant',
        // url: 'http://localhost:3000/api/v1/applicant',
        method: 'POST',
        data: formData,
        timeout: 20000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          // this.setTestTimeout()
          // for (var key of formData.entries()) {
          //   console.log(key[0] + ', ' + key[1])
          // }

          let isSuccess = true
          let status = 0
          if (response) {
            if (
              Object.prototype.hasOwnProperty.call(response.data, 'message')
            ) {

              if (
                Object.prototype.hasOwnProperty.call(response.data.message, 'details') &&
                Object.prototype.hasOwnProperty.call(response.data.message.details, 'error') 
              ) {
                status = response.data.message.details.errorCode
              } else {
                //status = response.data.message.status.affectedRows
                response.data.message.status.forEach(element => {
                  if(element.affectedRows != 1)
                    isSuccess = false
                });
                if(!isSuccess)
                  status = 0
                else
                  status = 1
              }
            }

            
            // redirect to response page
            this.$router.push('/deepsea/response/' + status)
            // this.$router.push({ name: 'deepsea-applicant-response' } + this.posId + '/' + status)
          }
        })
        .catch(err => {
          console.log(err.code)
          console.log(err.message)
          console.log(err.stack)
        })
        .finally(() => {
          this.response_loading = false
        })
    },
    onSelectFile(fileType) {
      if (fileType === 'resume') {
        this.applicant.files.resume = this.$refs.fileInputResume.files[0]
      } else if (fileType === 'cover-letter') {
        this.applicant.files.cover = this.$refs.fileInputCoverLetter.files[0]
      }
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validZip: function(zip) {
      var re = /^\d{5}(?:[-\s]\d{4})?$/
      return re.test(zip)
    },
    validPhone: function(phone) {
      // var re = /(?:[ .-][0-9]{3}){1,5}/
      var re = /^[0-9]{3}?[-.]?[0-9]{3}[-.]?[0-9]{4,6}$/
      return re.test(phone)
    },
    confirmEmail: function(email, confirm) {
      if (email === confirm) {
        return true
      }
      return false
    },
    validFile: function (file) {
      const allowedTypes = [
        'text/plain', // .txt
        'application/pdf',
        'application/rtf',
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      ]

      if (!file || !allowedTypes.includes(file.type.toLowerCase())) {
        return false
      }

      const allowedExtensions = [
        'txt',
        'rtf',
        'pdf',
        'doc',
        'docx'
      ]

      const extension = file.name ? file.name.split('.').pop() : ''

      if (!allowedExtensions.includes(extension.toLowerCase())) {
        return false
      }

      return true
    },
    validSalary: function(start, end) {
      if (Number(start) < Number(end)) {
        return true
      }
      return false
    },
    validReference: function(ref_fk) {
      // console.log('reference fk = ' + ref_fk)
      if (parseInt(ref_fk) == 0) {
        return false
      }
      return true
    },
    validSponsor: function(spons) {
      if (spons == null || spons == '') {
        return false
      }
      return true
    },
    async checkForm() {
      await this.$nextTick()
      if (this.response_loading) return

      this.response_loading = true
      this.errors = []
      // Name
      if (!this.applicant.first_name) {
        this.errors.push('First Name required')
      }
      if (!this.applicant.last_name) {
        this.errors.push('Last Name required')
      }
      // Email
      if (!this.applicant.email || !this.validEmail(this.applicant.email)) {
        this.errors.push('Valid Email required')
      }
      if (
        !this.confirmEmail(this.applicant.email, this.applicant.confirmEmail)
      ) {
        this.errors.push('Emails do not match')
      }
      if (!this.applicant.confirmEmail) {
        this.errors.push('Emails do not match')
      }
      // Phone
      if (!this.applicant.phone || !this.validPhone(this.applicant.phone)) {
        this.errors.push('Valid Phone Number required')
      }
      // Address
      if (!this.applicant.address) {
        this.errors.push('Address required')
      }

      // City
      if (!this.applicant.city) {
        this.errors.push('City required')
      }
      // State
      // if (!this.applicant.state) {
      //   this.errors.push('State required')
      // }
      if (!this.applicant.state) {
        this.errors.push('State required')
      } else {
        // list of states for converting names to abbv
        const stateList = {
          'AL': 'Alabama',
          'AK': 'Alaska',
          'AZ': 'Arizona',
          'AR': 'Arkansas',
          'CA': 'California',
          'CO': 'Colorado',
          'CT': 'Connecticut',
          'DE': 'Delaware',
          'DC': 'District of Columbia',
          'FL': 'Florida',
          'GA': 'Georgia',
          'HI': 'Hawaii',
          'ID': 'Idaho',
          'IL': 'Illinois',
          'IN': 'Indiana',
          'IA': 'Iowa',
          'KS': 'Kansas',
          'KY': 'Kentucky',
          'LA': 'Louisiana',
          'ME': 'Maine',
          'MD': 'Maryland',
          'MA': 'Massachusetts',
          'MI': 'Michigan',
          'MN': 'Minnesota',
          'MS': 'Mississippi',
          'MO': 'Missouri',
          'MT': 'Montana',
          'NE': 'Nebraska',
          'NV': 'Nevada',
          'NH': 'New Hampshire',
          'NJ': 'New Jersey',
          'NM': 'New Mexico',
          'NY': 'New York',
          'NC': 'North Carolina',
          'ND': 'North Dakota',
          'OH': 'Ohio',
          'OK': 'Oklahoma',
          'OR': 'Oregon',
          'PA': 'Pennsylvania',
          'RI': 'Rhode Island',
          'SC': 'South Carolina',
          'SD': 'South Dakota',
          'TN': 'Tennessee',
          'TX': 'Texas',
          'UT': 'Utah',
          'VT': 'Vermont',
          'VA': 'Virginia',
          'WA': 'Washington',
          'WV': 'West Virginia',
          'WI': 'Wisconsin',
          'WY': 'Wyoming'
        }

        let s = 0
        for (let [key, value] of Object.entries(stateList)) {
          if (value == this.applicant.state || key == this.applicant.state) {
            // console.log('State abbv is: ' + key)
            s = 1
            this.applicant.state = key
          }
        }

        if (s != 1) {
          // console.log('invalid state')
          this.errors.push('Not a valid state')
        }
      }
      // Zip Code
      if (!this.applicant.zip || !this.validZip(this.applicant.zip)) {
        this.errors.push('Valid Zip Code required')
      }
      // Salary
      if (!this.applicant.req_sal_start) {
        this.errors.push('Minimum Salary required')
      }
      if (!this.applicant.req_sal_end) {
        this.errors.push('Maximum Salary required')
      }
      if (
        !this.validSalary(
          this.applicant.req_sal_start,
          this.applicant.req_sal_end
        )
      ) {
        this.errors.push('Starting salary must be less than ending')
      }

      if (!this.applicant.files.cover) {
        this.errors.push('Cover Letter required')
      }

      if (!this.validFile(this.applicant.files.cover)) {
        this.errors.push('Cover Letter filetype must be one of: .pdf, .txt, .doc, .docx, .rtf')
      }

      if (!this.applicant.files.resume) {
        this.errors.push('Resume required')
      }

      if (!this.validFile(this.applicant.files.resume)) {
        this.errors.push('Resume filetype must be one of: .pdf, .txt, .doc, .docx, .rtf')
      }

      if (!this.validSponsor(this.applicant.work_us)) {
        this.errors.push('Sponsorship inquiry required')
      }

      if (
        !this.applicant.ref_fk ||
        !this.validReference(this.applicant.ref_fk)
      ) {
        this.errors.push('Referred By required')
      }

      // console.log(this.applicant)

      if (!this.errors.length) {
        if (this.submitTimeoutId) clearTimeout(this.submitTimeoutId)

        this.submitTimeoutId = setTimeout(() => {
          this.handleSubmit()
        }, 1000)
        // setTimeout(() => {
        //   // this.response_loading = true
        //   this.handleSubmit()
        //   // this.response_loading = false
        // }, 800)

        // this.response_loading = false
      } else {
        console.log('unable to submit, errors found')
        this.response_loading = false
      }
    }
  }
}
</script>

<style scoped>
/* form */
div#deepsea-applicant-form {
  background-image: linear-gradient(180deg, #192b6d 0%, #000000 100%);
  background-position: center center;
  background-repeat: no-repeat;
}
form span.asterisk {
  color: #ffda00;
  margin-left: 0.1em;
}
/* fieldsets */
fieldset#positions {
  border: 1px solid #aaa;
  border-radius: 16px;
  margin-top: 0;
}
fieldset#positions:hover {
  border-color: #ffda00;
}
fieldset#positions legend {
  text-align: left;
  font-weight: 600;
  font-size: smaller;
}
fieldset#positions label {
  margin: 0 0 .5rem;
}
fieldset {
  border: none;
}
fieldset legend {
  color: #ffda00;
  font-weight: bold;
  text-align: center;
}
fieldset label {
  text-align: left;
}
fieldset input[type='text'],
fieldset select,
fieldset div.state input {
  border: 1px solid #aaa;
  border-radius: 25px;
  color: white;
  line-height: normal;
  width: 100%;
  padding: 0.75rem 1rem;
}
fieldset select,
fieldset div.state input {
  background: transparent url('../assets/icons/icon-select.svg') no-repeat
    calc(100% - 1rem) center;
  background-size: 16px 16px;
}
/* fix overlapping Chrome default icon */
fieldset div.state input::-webkit-calendar-picker-indicator {
  display: none !important;
}
fieldset select.dropdown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
fieldset div.state input#state:active,
fieldset div.state input#state:focus,
fieldset input[type='text']:active,
fieldset input[type='text']:focus,
fieldset select:active,
fieldset select:focus {
  border: 1px solid #ffda00;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 6px #ffda00;
}
/* fix to keep drop down color visible in Chrome */
fieldset select#ref option {
  color: #000000;
}
fieldset div.salary-type,
fieldset div.sponsorship,
fieldset div.salary-type,
fieldset div.sponsorship {
  flex-direction: column;
}
fieldset div.radio-group {
  display: flex;
}
fieldset div input[type="radio"],
fieldset div input[type="checkbox"] {
  cursor: pointer;
  border: 1px solid #aaa;
  transition: 0.2s all linear;
  margin-right: 8px;
  position: relative;
}
fieldset div input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 16px;
  height: 16px;

  border-radius: 50%;
  top: 3px;
}
fieldset div input[type='radio']:checked {
  border: 5px solid #ffda00;
}
fieldset div.salary-type label,
fieldset div.sponsorship label {
  margin: 0 16px 0 0;
  font-weight: normal;
}
fieldset div input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 16px;
  height: 16px;

  border-radius: 10%;
  top: 4px;
}
fieldset div input[type='checkbox']:checked {
  border: 1px solid #ffda00;
  background: url('../assets/icons/icon-checkmark.svg') #ffda00 no-repeat center / 70% auto;
}

/* Privacy START */
p small a.privacy-link {
  color: white;
  font-size: 1em;
  transition: all 0.4s ease;
}
p small a.privacy-link:hover {
  color: #ffda00;
  cursor: pointer;
  font-size: 1.1em;
  text-decoration: none;
} /* Privacy END */

/* Upload Files */
fieldset.upload-files {
  width: 100%;
  padding: 0 1rem;
}
p.file-types {
  padding: 0;
}
div.cover-letter {
  padding-top: 1rem;
  width: 100%;
}
div.cover-letter p,
div.resume p {
  padding-bottom: 8px;
  font-weight: 600;
}
div.cover-letter p small,
div.resume p small {
  font-weight: normal;
}
input[type='file'] {
  width: 100%;
  height: 0;
  padding: 0;
  opacity: 0;
  align-self: center;
  cursor: pointer;
}
label[for='cover-letter'],
label[for='resume'] {
  cursor: pointer;
  background-image: linear-gradient(to bottom, #192b6d 0%, #21409a 100%);
  /* border: 1px solid white; */
  border-radius: 25px;
  box-sizing: border-box;
  display: block;
  position: relative;

  color: white;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 2px;
  margin: 0 auto;
  padding: 2px;

  text-transform: uppercase;
  width: fit-content;
}
label[for='cover-letter'] span,
label[for='resume'] span {
  background: #000000;
  border-radius: 25px;

  display: flex;
  justify-content: center;

  padding: 10px 36px;

  height: 100%;
  width: 100%;

  transition: background 0.2s ease;
}

label[for='cover-letter']:hover,
label[for='resume']:hover {
  background: #ffda00;
  color: #ffda00;
}

label[for='cover-letter'] span:hover,
label[for='resume'] span:hover {
  background: black;
  color: #ffda00;
}

/* input[type='file'] > input[type='button'] {
  display: none;
} */

/* Errors */
div#err-summary {
  padding: 8%;
  border-radius: 24px;
  color: #fff;
  border: 1px solid #cc0000;
  /* background-color: transparent; */
  background-color: #f5c6cb;
  text-align: left;
}

div#err-summary strong {
  color: #cc0000;
}

ul.error {
  list-style: none;
  color: #cc0000;
}

#deepsea-applicant-form form fieldset .input-err {
  border: 1px solid #cc0000;
}

/* .input-err {
  border: 1px solid #cc0000 !important;
} */
</style>