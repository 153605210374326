<template>
  <footer class="footer" id="deepsea" v-if="deepsea">
    <section>
      <h3>Our Company</h3>
      <a href="https://www.deepsea.com/about-us/">About Us</a>
      <a href="https://www.deepsea.com/our-facility/">Our Facility</a>
      <a href="https://www.deepsea.com/our-history/">Our History</a>
      <a href="https://www.deepsea.com/terms-and-conditions/"
        >Terms &amp; Conditions</a
      >
      <a href="https://www.deepsea.com/intellectual-property/"
        >Intellectual Property</a
      >
      <a href="https://www.deepsea.com/services/">Services</a>
      <a href="https://www.deepsea.com/warranty/">Warranty</a>
    </section>
    <section>
      <h3>Careers</h3>
      <a href="https://www.deepsea.com/life-at-deepsea">Life at DeepSea</a>
      <a href="https://careers.seescan.com/">Job Opportunities</a>
    </section>
    <section>
      <h3>Learn More</h3>
      <a href="https://www.deepsea.com/knowledgebase/">Knowledgebase</a>
      <a href="https://www.deepsea.com/products/">Our Products</a>
    </section>
    <section>
      <h3>Get in Touch</h3>
      <a href="https://www.deepsea.com/contact_us/">Contact Us</a>
    </section>
    <section class="social-media">
      <!-- DeepSea Social Media -->
      <h3>Social</h3>
      <div class="social-media">
        <!-- Facebook -->
        <a
          href="https://www.facebook.com/DeepSea.Oceanographic/"
          target="_blank"
        >
          <img
            src="https://www.deepsea.com/wp-content/uploads/2022/01/Facebook.png"
            alt="Facebook"
            width="35"
            height="35"
          />
        </a>
        <!-- YouTube -->
        <a
          href="https://www.youtube.com/channel/UCg9DG-24ffew829FNziz1Hg"
          target="_blank"
        >
          <img
            src="https://www.deepsea.com/wp-content/uploads/2022/01/YouTube.png"
            alt="YouTube"
            width="35"
            height="35"
          />
        </a>
        <!-- Twitter -->
        <a href="https://twitter.com/deepsea_pl?lang=en" target="_blank">
          <img
            src="https://www.deepsea.com/wp-content/uploads/2022/01/Twitter.png"
            alt="Twitter"
            width="35"
            height="35"
          />
        </a>
        <!-- LinkedIn -->
        <a
          href="https://www.linkedin.com/company/deepsea-power-&-light/"
          target="_blank"
        >
          <img
            src="https://www.deepsea.com/wp-content/uploads/2022/01/LinkedIn.png"
            alt="LinkedIn"
            width="35"
            height="35"
          />
        </a>
      </div>
      <!-- DeepSea Sitemap -->
      <a class="sitemap" href="https://www.deepsea.com/sitemap/">
        <h3>Sitemap</h3>
      </a>
    </section>
  </footer>
  <footer class="footer" id="seescan" v-else>
    <section>
      <h3>Our Company</h3>
      <a href="https://www.seescan.com/about-us/">About Us</a>
      <a href="https://www.seescan.com/our-history/">Our History</a>
      <a href="https://www.seescan.com/our-campus/">Our Campus</a>
      <a href="https://www.seescan.com/terms-and-conditions/">
        Terms &amp; Conditions
      </a>
      <a href="https://www.seescan.com/intellectual-property/">
        Intellectual Property
      </a>
      <a href="https://www.seescan.com/covid-19/">COVID-19</a>
    </section>
    <section>
      <h3>Careers</h3>
      <a href="https://www.seescan.com/life-at-seescan/">Life at SeeScan</a>
      <router-link to="/" :active="isActive">Job Opportunities</router-link>
      <!-- <a href="#">Job Opportunitites</a> -->
    </section>
    <section>
      <h3>Learn More</h3>
      <a href="https://www.seescan.com/knowledgebase/">Knowledgebase</a>
      <a href="https://www.seescan.com/geo-locating/">Geo Locating</a>
      <a href="https://www.seescan.com/products/">Our Products</a>
    </section>
    <section>
      <h3>Get In Touch</h3>
      <a href="https://www.seescan.com/contact_us/">Contact Us</a>
      <h3 v-if="!mobileView">Repair</h3>
      <a v-if="!mobileView" href="https://www.seescan.com/repair/"
        >Factory Direct Service</a
      >
    </section>
    <section v-if="mobileView">
      <h3>Repair</h3>
      <a href="https://www.seescan.com/repair/">Factory Direct Service</a>
    </section>
    <section class="social-media">
      <h3>Social</h3>
      <!-- social media -->
      <div class="social-media">
        <!-- Facebook -->
        <a
          href="https://www.facebook.com/SeeScan-168217610038735/"
          target="_blank"
        >
          <img
            src="https://www.seescan.com/wp-content/uploads/2019/12/Facebook-Icon.png"
            alt="Facebook"
            width="35"
            height="35"
          />
        </a>
        <!-- Instagram -->
        <a href="https://www.instagram.com/seescanofficial/" target="_blank">
          <img
            src="https://www.seescan.com/wp-content/uploads/2019/12/Instagram-Icon.png"
            alt="Instagram"
            width="35"
            height="35"
          />
        </a>
        <!-- YouTube -->
        <a
          href="https://www.youtube.com/channel/UCcAdMAULSj43y1LiiSMGIZw"
          target="_blank"
        >
          <img
            src="https://www.seescan.com/wp-content/uploads/2019/12/YouTube-Icon.png"
            alt="YouTube"
            width="35"
            height="35"
          />
        </a>
        <!-- Twitter -->
        <a href="https://twitter.com/seescan" target="_blank">
          <img
            src="https://www.seescan.com/wp-content/uploads/2019/12/Twitter-Icon.png"
            alt="Twitter"
            width="35"
            height="35"
          />
        </a>
        <!-- LinkedIn -->
        <a href="https://www.linkedin.com/company/seescan/" target="_blank">
          <img
            src="https://www.seescan.com/wp-content/uploads/2019/12/LinkedIn-Icon.png"
            alt="LinkedIn"
            width="35"
            height="35"
          />
        </a>
      </div>
      <!-- SeeScan Sitemap -->
      <a
        class="sitemap"
        v-if="!mobileView"
        href="https://www.seescan.com/sitemap/"
      >
        <h3>Sitemap</h3>
      </a>
    </section>
    <section v-if="mobileView">
      <a class="sitemap" href="https://www.seescan.com/sitemap/">
        <h3>Sitemap</h3>
      </a>
    </section>
  </footer>
  <footer class="footer-copyright deepsea-copyright" v-if="deepsea">
    <img
      src="https://www.deepsea.com/wp-content/uploads/2021/03/logo-deepsea-white.png"
      width="250px"
      height="200px"
    />
    <!-- DeepSea Legal -->
    <Popover class="legal">
      <div class="legal-copy">
        DeepSea is a business division of SeeScan, Inc. All registered and
        unregistered trademarks and logos mentioned herein are the property of
        their respective owners. Copyright (C) 2022 SeeScan, Inc. All rights
        reserved.
      </div>
    </Popover>
  </footer>
  <footer class="footer-copyright seescan-copyright" v-else>
    <img
      src="https://www.seescan.com/wp-content/uploads/2020/07/Underground_Evolved_Horizontal-6_c-1.png"
      width="250px"
      height="200px"
    />
    <!-- SeeScan Legal -->
    <Popover class="legal">
      <div class="legal-copy">
        RIDGID and the RIDGID logo are trademarks of Ridge Tool Company,
        registered in the USA and other countries. All other registered and
        unregistered trademarks and logos mentioned herein are the property of
        their respective owners. Copyright © 2021 SeeScan. All rights reserved.
      </div>
    </Popover>
  </footer>
</template>

<script>
import Popover from '@/components/Popover.vue'
export default {
  name: 'Footer',
  components: {
    Popover
  },
  props: {
    deepsea: {
      type: Boolean
    }
  },
  data() {
    return {
      mobileView: false
    }
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 1100
    }
  },
  created() {
    this.handleView()
  }
}
</script>

<style>
/*Mobile First Default */
footer#deepsea {
  background: white;
  color: black;
  border-top: 4px #ffda00 solid;
}
footer#seescan {
  background: black;
  color: white;
}
.footer {
  padding: 70px 30px 40px 30px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}
section {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
#deepsea h3,
#deepsea a {
  color: #000000;
}
#seescan h3 {
  color: #f2f2f2;
}
#seescan a {
  color: #999999;
}
a {
  font-size: 0.875em;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
}
/* states */
#seescan a:hover,
#seescan a:active,
#seescan a:focus {
  color: #59ea62;
  text-decoration: none;
  font-weight: bold;
}
#deepsea a:hover,
#deepsea a:active,
#deepsea a:focus {
  color: #21409a;
  text-decoration: none;
  font-weight: bold;
}
/* social media */
.social-media {
  display: inline-flex;
}
.social-media a img {
  margin: 10px 10px 0 0;
}
a.sitemap {
  font-size: unset;
}
.deepsea-copyright {
  background-color: #192b6d;
}
.seescan-copyright {
  background-color: #000000;
  border-top: 1px solid #59ea62;
}
/* copyright */
.footer-copyright {
  padding: 16px 30px;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}
.footer-copyright img {
  height: auto;
  max-width: 250px;
  width: 50%;
}
.footer-copyright .legal {
  font-size: 11px;
  color: white;
  cursor: pointer;
}
.footer-copyright .legal .legal-copy {
  padding: 0.75em 1em;
  color: #747474;
  font-size: 13px;
  line-height: 18px;
}
@media screen and (min-width: 1100px) {
  .footer {
    flex-direction: row;
    padding: 70px 70px 40px 70px;
  }
  .footer-copyright {
    padding-left: 70px;
    padding-right: 70px;
  }
  section {
    width: 20%;
  }
  .social-media {
    flex-wrap: wrap;
  }
}
</style>
