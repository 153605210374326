<template>
  <Popper arrow hover placement="top">
    <span>Legal</span>
    <template #content>
      <div id="legal">Legal</div>
      <slot />
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from 'vue'
import Popper from 'vue3-popper'

export default defineComponent({
  components: {
    Popper
  }
})
</script>

<style>
#legal {
  color: #747474;
  background: #f2f2f2;
  padding: 1em;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid #eeeeee;
}
.popper {
  width: 200px;
  font-size: 13px;
  text-align: left;
}
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #000000;
  --popper-theme-border-style: solid 1px #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
