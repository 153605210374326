<template>
  <!-- DeepSea Navigation Bar -->
  <div class="navigation-bar" id="deepsea-nav-bar">
    <!-- DeepSea Logo -->
    <div class="logo" id="deepsea-logo">
      <a class="block" href="http://www.deepsea.com"></a>
    </div>
    <!-- DeepSea Desktop Navigation -->
    <DesktopNav v-if="!mobileView" :deepsea="true" />
    <!-- DeepSea Mobile Icon -->
    <button
      v-if="mobileView"
      class="nav-icon"
      @click="showMobileNav = !showMobileNav"
    >
      <font-awesome-icon class="menu fa-lg" icon="bars" />
    </button>
    <!-- DeepSea Mobile Navigation -->
    <Transition name="slide-fade">
      <MobileNav
        v-if="showMobileNav"
        v-click-away="onClickAway"
        :deepsea="true"
      />
    </Transition>
  </div>
</template>

<script>
import MobileNav from '@/components/MobileNav.vue'
import DesktopNav from '@/components/DesktopNav.vue'
import { directive } from 'vue3-click-away'

export default {
  name: 'DeepSea Navigation',
  components: {
    MobileNav,
    DesktopNav
  },
  data() {
    return {
      mobileView: false,
      showMobileNav: false
    }
  },
  directives: {
    ClickAway: directive
  },
  methods: {
    onClickAway(event) {
      console.log(event)
      this.showMobileNav = false
    },
    handleView() {
      this.mobileView = window.innerWidth <= 1355
    }
  },
  mounted() {
    this.handleView()
    window.addEventListener('resize', this.handleView, { passive: true })
  }
}
</script>

<style scoped>
/* Mobile First */
#deepsea-logo {
  background-image: url('../assets/images/logo-deepsea.png');
  height: 46px;
  max-width: 250px;
  margin: 0px;
}
@media screen and (min-width: 1100px) {
  #deepsea-logo {
    margin: 32px; /* increase margin */
  }
}
</style>
