<template>
  <!-- SeeScan Navigation Bar -->
  <div class="navigation-bar" id="seescan-nav-bar">
    <!-- SeeScan Logo -->
    <div class="logo" id="seescan-logo">
      <a class="block" href="http://www.seescan.com"></a>
    </div>
    <!-- SeeScan Desktop Navigation -->
    <DesktopNav v-if="!mobileView" />
    <!-- SeeScan Mobile Icon -->
    <button
      v-if="mobileView"
      class="nav-icon"
      @click="showMobileNav = !showMobileNav"
    >
      <font-awesome-icon class="menu fa-lg" icon="bars" />
    </button>
    <!-- SeeScan Mobile Navigation -->
    <Transition name="slide-fade">
      <MobileNav v-if="showMobileNav" v-click-away="onClickAway" />
    </Transition>
  </div>
</template>

<script>
import MobileNav from '@/components/MobileNav.vue'
import DesktopNav from '@/components/DesktopNav.vue'
import { directive } from 'vue3-click-away'

export default {
  name: 'SeeScan Navigation',
  components: {
    MobileNav,
    DesktopNav
  },
  data() {
    return {
      mobileView: false,
      showMobileNav: false
    }
  },
  directives: {
    ClickAway: directive
  },
  methods: {
    onClickAway(event) {
      console.log(event)
      this.showMobileNav = false
    },
    handleView() {
      this.mobileView = window.innerWidth <= 1100
    }
  },
  mounted() {
    this.handleView()
    window.addEventListener('resize', this.handleView, { passive: true })
  }
}
</script>

<style>
#seescan-logo {
  background-image: url('../assets/images/logo-seescan.png');
  height: 23px;
  max-width: 124px;
}
</style>
