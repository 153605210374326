<template>
  <p v-if="pos_loading">Loading...</p>
  <h2 class="title">{{ position.title }}</h2>
  <Separator />
  <div v-html="position.summary" id="summary"></div>
  <router-link :to="{ name: 'applicant-form', params: { id: posId } }">
    <Button>Apply Now</Button>
  </router-link>
  <p v-if="pos_error">{{ pos_error }}</p>
</template>

<script>
import axios from 'axios'
import Button from '@/components/buttons/Button.vue'
import Separator from '@/components/Separator.vue'

export default {
  name: 'position-description',
  data() {
    return {
      pos_loading: false,
      position: {
        title: null,
        summary: null,
      },
      pos_error: '',
      posId: null,
    }
  },
  created: function () {
    this.posId = this.$route.params.id

    if (this.posId == 1) {
      // redirect to index if general job passed
      this.$router.push('/')
    }

    let positionUrl =
      'https://careers-api.seescan.com/api/v1/positions/id/' + this.posId
    // let positionUrl = 'http://localhost:3000/api/v1/positions/id/' + this.posId;

    axios
      .get(positionUrl)
      .then((response) => {
        const responsePos = response.data
        this.position = responsePos.data.position
      })
      .catch((errors) => {
        console.error(errors)

        if (errors.response.status == 404) {
          this.$router.push('/')
        }
      })
  },
  components: {
    Button,
    Separator,
  },
}
</script>

<style>
/* mobile first */
h2.title {
  color: #5bba47;
  padding-top: 8%;
}
div#summary {
  padding-bottom: 24px;
}
#summary p,
#summary ul,
#summary {
  text-align: left;
}
#summary p,
#summary {
  padding: 0px 10%;
}
#summary p {
  padding: 8px 0px;
}
#summary ul {
  margin: 0.2rem 1rem;
}
#summary li {
  list-style-position: inside;
  list-style-type: square;
}
#summary li li {
  list-style-type: circle;
}
/* large screens */
@media screen and (min-width: 1100px) {
  #summary ul,
  #summary p,
  #summary {
    text-align: left;
    padding: 0px 12%;
  }
  #summary p,
  #summary ul {
    padding-block-end: 14px;
  }
  #summary ul ul {
    padding: 0 4%;
  }
  #summary ul li:last-child {
    padding-bottom: 12px;
  }
}
</style>
