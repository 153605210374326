<template>
  <div class="navigation">
    <!-- DeepSea Desktop Navigation -->
    <nav class="deepsea-nav" v-if="deepsea">
      <ul>
        <!-- DeepSea Home -->
        <li><a href="http://www.deepsea.com">Home</a></li>
        <!-- Our Company - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showCoDropdown = !showCoDropdown"
          @mouseleave="showCoDropdown = !showCoDropdown"
        >
          <div class="dropdown-li">
            <a href="#">
              <span>Our Company</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- Our Company - Dropdown Submenu -->
          <ul class="dropdown-links deepsea-submenu" v-if="showCoDropdown">
            <li><a href="https://www.deepsea.com/about-us/"> About Us</a></li>
            <li>
              <a href="https://www.deepsea.com/our-history/">
                Our History
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/our-campus/">
                Our Campus
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/terms-and-conditions/">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/intellectual-property/">
                Intellectual Property
              </a>
            </li>
          </ul>
        </li>
        <!-- Our Products - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showProductsDropdown = !showProductsDropdown"
          @mouseleave="showProductsDropdown = !showProductsDropdown"
        >
          <div class="dropdown-li">
            <a href="https://www.deepsea.com/products/">
              <span>Our Products</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- Our Products - Dropdown Submenu -->
          <ul
            class="dropdown-links deepsea-submenu"
            v-if="showProductsDropdown"
          >
            <li>
              <a href="https://www.deepsea.com/products/#IP-Cameras">
                Cameras
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#LED-Lights">
                Lights
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Lasers">
                Lasers
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Relief-Valves">
                Relief Valves
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Batteries">
                Power
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Accessories">
                Accessories
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/products/#Technology">
                Technology
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/services/">
                Services
              </a>
            </li>
          </ul>
        </li>
        <!-- DeepSea Services - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showServicesDropdown = !showServicesDropdown"
          @mouseleave="showServicesDropdown = !showServicesDropdown"
        >
          <div class="dropdown-li">
            <a href="https://www.deepsea.com/services" class="">
              <span>Services</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- DeepSea Services - Dropdown Submenu -->
          <ul
            class="dropdown-links deepsea-submenu"
            v-if="showServicesDropdown"
          >
            <li>
              <a href="https://www.deepsea.com/services/#pressure">
                Pressure Testing
              </a>
            </li>
            <li>
              <a href="https://www.deepsea.com/services/#optical">
                Optical Measurement
              </a>
            </li>
          </ul>
        </li>
        <!-- DeepSea Careers - Dropdown-->
        <li
          class="dropdown"
          @mouseenter="showCareersDropdown = !showCareersDropdown"
          @mouseleave="showCareersDropdown = !showCareersDropdown"
        >
          <div class="dropdown-li">
            <a href="#" class="active">
              <span>Careers</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- DeepSea Careers - Dropdown Submenu -->
          <ul class="dropdown-links deepsea-submenu" v-if="showCareersDropdown">
            <li>
              <a href="https://www.deepsea.com/life-at-deepsea/">
                Life at DeepSea
              </a>
            </li>
            <li>
              <router-link to="/deepsea" :active="isActive" @click="setFavicon"
                >Job Opportunities</router-link
              >
            </li>
          </ul>
        </li>
        <!-- DeepSea Knowledgebase - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showKnowledgebaseDropdown = !showKnowledgebaseDropdown"
          @mouseleave="showKnowledgebaseDropdown = !showKnowledgebaseDropdown"
        >
          <div class="dropdown-li">
            <a href="https://www.deepsea.com/knowledgebase/">
              <span>Knowledgebase</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- DeepSea Knowledgebase - Dropdown Submenu -->
          <ul
            class="dropdown-links deepsea-submenu"
            v-if="showKnowledgebaseDropdown"
          >
            <li>
              <a
                href="https://www.deepsea.com/knowledgebase/#underwater-imaging"
                >Underwater Imaging</a
              >
            </li>
            <li>
              <a
                href="https://www.deepsea.com/knowledgebase/#underwater-lighting"
                >Underwater Lighting</a
              >
            </li>
            <li>
              <a
                href="https://www.deepsea.com/knowledgebase/#technical-resources"
                >Technical Resources</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/knowledgebase/#products-in-use"
                >Products In Use</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/knowledgebase/#design-tools"
                >Design Tools</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/knowledgebase/#pressure-testing"
                >Pressure Testing</a
              >
            </li>
          </ul>
        </li>
        <!-- DeepSea Contact Us - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showContactDropdown = !showContactDropdown"
          @mouseleave="showContactDropdown = !showContactDropdown"
        >
          <div class="dropdown-li">
            <a href="https://www.deepsea.com/contact-us/">
              <span>Contact Us</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- DeepSea Contact Us - Dropdown Submenu -->
          <ul class="dropdown-links deepsea-submenu" v-if="showContactDropdown">
            <li>
              <a href="https://www.deepsea.com/contact-us/#sales"
                >Contact Sales</a
              >
            </li>
            <li>
              <a href="https://www.deepsea.com/contact-us/#our-representatives"
                >Our Representatives</a
              >
            </li>
          </ul>
        </li>
        <!-- DeepSea Support - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showSupportDropdown = !showSupportDropdown"
          @mouseleave="showSupportDropdown = !showSupportDropdown"
        >
          <div class="dropdown-li">
            <a href="#">
              <span>Support</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- DeepSea Support - Dropdown Submenu -->
          <ul
            class="dropdown-links deepsea-submenu"
            v-if="showSupportDropdown"
            style="right: 85px"
          >
            <li>
              <a href="https://www.deepsea.com/product-documentation/">
                Product Documentation
              </a>
            </li>
          </ul>
        </li>
        <!-- DeepSea Search -->
        <li class="search">
          <a id="search-icon" @click.enter="showSearch = !showSearch">
            <font-awesome-icon icon="magnifying-glass" />
          </a>
          <!-- Search Bar -->
          <Search v-if="showSearch" v-click-away="onClickAway" />
        </li>
      </ul>
    </nav>
    <!-- SeeScan Desktop Navigation -->
    <nav class="seescan-nav" v-else>
      <ul>
        <!-- SeeScan Home -->
        <li>
          <a href="http://www.seescan.com">Home</a>
        </li>
        <!-- Our Company - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showCoDropdown = !showCoDropdown"
          @mouseleave="showCoDropdown = !showCoDropdown"
        >
          <div class="dropdown-li">
            <a href="#">
              <span>Our Company</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- Our Company - Dropdown Submenu -->
          <ul class="dropdown-links seescan-submenu" v-if="showCoDropdown">
            <li>
              <a href="https://www.seescan.com/about-us/">
                About Us
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/our-history/">
                Our History
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/our-campus/">
                Our Campus
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/terms-and-conditions/">
                Terms &amp; Conditions
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/intellectual-property/">
                Intellectual Property
              </a>
            </li>
            <li>
              <a href="https://www.seescan.com/covid-19/">
                COVID-19
              </a>
            </li>
          </ul>
        </li>
        <!-- Seescan Careers - Dropdown -->
        <li
          class="dropdown"
          @mouseenter="showCareersDropdown = !showCareersDropdown"
          @mouseleave="showCareersDropdown = !showCareersDropdown"
        >
          <div class="dropdown-li">
            <a href="#" class="active">
              <span>Careers</span>
              <span class="dropdown-icon">
                <BaseIcon :iconName="dropdown" :width="10" :height="10">
                  <IconDropdown />
                </BaseIcon>
              </span>
            </a>
          </div>
          <!-- Seescan Careers - Dropdown Submenu -->
          <ul class="dropdown-links seescan-submenu" v-if="showCareersDropdown">
            <li>
              <a href="https://www.seescan.com/life-at-seescan/">
                Life at SeeScan
              </a>
            </li>
            <li>
              <router-link to="/" :active="isActive" @click="setFavicon"
                >Job Opportunities</router-link
              >
            </li>
          </ul>
        </li>
        <!-- SeeScan Knowledgebase -->
        <li>
          <a href="https://www.seescan.com/knowledgebase/">Knowledgebase</a>
        </li>
        <!-- SeeScan Geo Locating -->
        <li>
          <a href="https://www.seescan.com/geo-locating/">Geo Locating</a>
        </li>
        <!-- SeeScan Our Products -->
        <li><a href="https://www.seescan.com/products/">Our Products</a></li>
        <!-- SeeScan Repair -->
        <li><a href="https://www.seescan.com/repair/">Repair</a></li>
        <!-- SeeScan Contact Us -->
        <li><a href="https://www.seescan.com/contact_us/">Contact Us</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import BaseIcon from './global/BaseIcon.vue'
import IconDropdown from './icons/IconDropdown.vue'
import Search from './Search.vue'
import { directive } from 'vue3-click-away'

export default {
  name: 'Desktop Navigation',
  components: {
    BaseIcon,
    IconDropdown,
    Search,
  },
  props: {
    deepsea: {
      type: Boolean,
    },
  },
  data() {
    return {
      showCareersDropdown: false,
      showCoDropdown: false,
      showContactDropdown: false,
      showKnowledgebaseDropdown: false,
      showProductsDropdown: false,
      showSearch: false,
      showServicesDropdown: false,
      showSupportDropdown: false,
    }
  },
  directives: {
    ClickAway: directive,
  },
  methods: {
    setFavicon() {
      const favicon = document.querySelector("[rel='icon']")
      favicon.setAttribute('href', this.$route.meta.icon)
    },
    onClickAway(event) {
      console.log(event)
      this.showSearch = false
    },
  },
}
</script>

<style scoped>
nav {
  align-items: flex-end;
}
nav ul {
  margin: 0;
}
nav ul li {
  display: inline-flex;
  padding: 0 24px 0 0;
  cursor: pointer;
}
nav ul li.search {
  padding-left: 16px;
  padding-right: 0;
  vertical-align: -2.5px;
}
nav ul li a#search-icon {
  border-color: transparent;
  height: 105px;
}
nav ul li a {
  color: white;
  font-size: 1em;
  text-decoration: none;
  box-sizing: border-box;
  height: 110px;

  display: flex;
  align-items: center;
  line-height: 1;
}
nav.seescan-nav ul li a:hover,
nav.seescan-nav ul li a:active,
nav.seescan-nav ul li a.active {
  color: #5bba47;
  border-top-color: #5bba47;
}
nav.deepsea-nav ul li a:hover,
nav.deepsea-nav ul li a:active,
nav.deepsea-nav ul li a.active {
  color: #ffda00;
  border-top-color: #ffda00;
}
nav ul li a:hover,
nav ul li a:active,
nav ul li a.active {
  border-top-width: 4px;
  border-top-style: solid;
}
/* dropdown */
nav ul li.dropdown div.dropdown-li {
  display: flex;
  flex-basis: auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.dropdown-icon {
  color: #f2f2f2;
  display: flex;
  padding-left: 8px;
}
nav.seescan-nav ul li:hover .dropdown-icon,
nav.seescan-nav ul li:hover .dropdown-icon:hover {
  color: #5bba47;
}
nav.deepsea-nav ul li:hover .dropdown-icon,
nav.deepsea-nav ul li:hover .dropdown-icon:hover {
  color: #ffda00;
}
nav ul li:hover .dropdown-icon,
nav ul li:hover .dropdown-icon:hover {
  background: transparent;
  border: none;
}
ul.deepsea-submenu {
  background: #192b6d;
  border-top: #ffda00 2px solid;
}
ul.seescan-submenu {
  background: black;
  border-top: #5bba47 2px solid;
}
ul.dropdown-links {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: absolute;
  top: 110px;
  width: 200px;
  z-index: 100;
}
ul.dropdown-links li {
  border-bottom: solid 1px #505050;
}
ul.deepsea-submenu li {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
ul.deepsea-submenu li:hover {
  background-color: rgba(114, 114, 114, 0.69);
}
ul.seescan-submenu li:hover {
  background-color: #8e8e8e;
}
ul.dropdown-links li a {
  height: unset;
  padding: 10px 20px;
  text-align: left;
}
ul.dropdown-links li:hover a:hover {
  border: none;
  color: white;
}
</style>
